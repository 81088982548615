import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import {
  Row, Col, Input, message,
} from 'antd'
import FloatLabel from '../FloatLabel'
import setBrandSettings from '../../api/setBrandSettings'
import isValidURL from '../../utils/isValidURL'

export default function Settings({ data }) {
  const [settings, setSettings] = useState({})
  const [existingSettings, setExistingSettings] = useState({})

  const db = firebase.firestore()

  useEffect(() => {
    db.collection('brands').doc(data.brand.id).collection('settings').doc('all')
      .get()
      .then(doc => {
        if (doc.exists) {
          setSettings(doc.data())
          setExistingSettings(doc.data())
        }
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const saveSettings = async (fieldID) => {
    if (settings[fieldID] !== existingSettings[fieldID]) {
      const response = await setBrandSettings(data.brand.id, fieldID, settings[fieldID])
      if (response === true) {
        message.success('Settings saved')
        setExistingSettings({ ...settings, [fieldID]: settings[fieldID] })
      } else {
        console.error(response)
        message.error('Something went wrong')
      }
    }
  }

  return (
    <div className="settings">
      <div className="dashboard-top" style={{ gridArea: 'dashboard-top' }}>
        <div className="title">
          <div className="greeting">
            <h1 className="bold">
              Settings
            </h1>
          </div>
        </div>
        <div className="separator" />
      </div>

      {data.brand.bynder && (
        <>
          <h1 className="mt-3">
            Bynder Integration
          </h1>
          <Row className="mt-5">
            <Col xs={24} lg={12} className="d-flex justify-between align-center">
              <FloatLabel
                label="Portal URL"
                value={settings?.bynderURL}
                className="w-100 mr-3"
              >
                <Input
                  value={settings?.bynderURL}
                  onChange={e => setSettings({ ...settings, bynderURL: e.target.value })}
                  onBlur={() => {
                    let url = settings.bynderURL
                    if (url.slice(-1) === '/') {
                      url = url.slice(0, -1)
                      setSettings({ ...settings, bynderURL: url })
                    }
                    if (isValidURL(url)) {
                      saveSettings('bynderURL')
                    } else {
                      message.error('url is not valid')
                    }
                  }}
                />
              </FloatLabel>
            </Col>
          </Row>
          <p className="mt-3">
            For example:
            <br />
            https://your-brand-portal.com
          </p>
          <Row className="mt-5">
            <Col xs={24} lg={12} className="d-flex justify-between align-center">
              <FloatLabel
                label="Permanent API token"
                value={settings?.bynderToken}
                className="w-100 mr-3"
              >
                <Input
                  value={settings?.bynderToken}
                  onChange={e => setSettings({ ...settings, bynderToken: e.target.value })}
                  onBlur={() => saveSettings('bynderToken')}
                />
              </FloatLabel>
            </Col>
          </Row>
          <p className="mt-3">
            Create your Permanent Token here:
            <br />
            {existingSettings.bynderURL || 'https://your-brand-portal.com'}/pysettings/permanent-tokens/
          </p>
        </>
      )}
    </div>
  )
}
