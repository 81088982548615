/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { ReactComponent as Image } from '../../icons/image.svg'

export default function StoryUploader({
  story, setStory, setFiles, files,
}) {

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png, video/mp4',
    onDrop: acceptedFiles => {
      const newFiles = acceptedFiles.map(file => {
        const extension = file.name.slice(file.name.lastIndexOf('.'))
        return Object.assign(
          file,
          {
            preview: URL.createObjectURL(file),
            extension,
          },
        )
      })
      setFiles([
        ...files,
        ...newFiles,
      ])
      setStory({
        ...story,
        imageFiles: [
          ...story.imageFiles,
          ...newFiles,
        ],
      })
    },
  })

  return (

    <div
      key="dropzone"
      className="dropzone-container post-container"
    >
      <div {...getRootProps({ className: 'dropzone post' })}>
        <input {...getInputProps()} />
        <Image className="secondary" />
        <p className="w-100 m-0">
          <span className="secondary bold">
            Upload files here
          </span>
          {' '}
          or just drag and drop
          <br />
          <span className="small">
            .jpg, .png, .mp4
          </span>
        </p>
      </div>
    </div>

  )
}
