/* eslint-disable react/jsx-props-no-spreading */
import {
  Button, message, Modal, Spin,
} from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import update from 'react-addons-update'
import getBynderBrands from '../../api/getBynderBrands'
import { ReactComponent as Image } from '../../icons/image.svg'
import setBrandSettings from '../../api/setBrandSettings'
import getBynderMedia from '../../api/getBynderMedia'

export default function StoryBynder({ story, setStory, data }) {
  const [modalStatus, setModalStatus] = useState()
  const [settings, setSettings] = useState()
  const [bynderBrands, setBynderBrands] = useState([])
  const [saveAsDefaultBrand, setSaveAsDefaultBrand] = useState(false)
  const [bynderBrand, setBynderBrand] = useState()
  const [bynderMedia, setBynderMedia] = useState([])
  const [loadingBynderMedia, setLoadingBynderMedia] = useState(false)

  const db = firebase.firestore()

  const loadBynderMedia = async (token, portalURL, brandId) => {
    setLoadingBynderMedia(true)
    const bynderMediaResponse = await getBynderMedia(token, portalURL, brandId)
    if (bynderMediaResponse.status === 200) {
      setBynderMedia(bynderMediaResponse.data)
    } else {
      message.error('Something went wrong loading images from Bynder, please check Bynder settings')
    }
    setLoadingBynderMedia(false)
  }

  useEffect(() => {
    (async () => {
      const savedSettings = await db.collection('brands').doc(data.brand.id).collection('settings').doc('all')
        .get()
        .then(doc => doc.data())
      if (savedSettings?.bynderURL && savedSettings?.bynderToken) {
        setSettings(savedSettings)

        const {
          bynderToken,
          bynderURL,
          defaultBynderBrand,
        } = savedSettings

        if (savedSettings.defaultBynderBrand) {
          loadBynderMedia(bynderToken, bynderURL, defaultBynderBrand.id)
        } else {
          const bynderBrandsResponse = await getBynderBrands(bynderToken, bynderURL)

          if (bynderBrandsResponse.status === 200) {
            setBynderBrands(bynderBrandsResponse.data)
          } else {
            message.error('Something went wrong loading brands from Bynder, please check Bynder settings')
          }
        }
      } else {
        message.error('Please set Bynder Portal URL and Token in the settings menu')
      }
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleBynderImage = image => {
    const imageURL = image.thumbnails.webimage

    if (story.images.includes(imageURL)) {
      const i = story.images.indexOf(imageURL)
      setStory(update(story, { images: { $splice: [[i, 1]] } }))
    } else {
      setStory(update(story, { images: { $push: [imageURL] } }))
    }
  }

  if (!settings) {
    return (
      <p className="primary bold">
        Please set Bynder Portal URL and Token in the  settings menu
      </p>
    )
  }

  return (
    <>
      <div
        key="dropzone"
        className="dropzone-container post-container"
        onClick={() => setModalStatus(true)}
      >
        <div className="dropzone post">
          <Image className="secondary" />
          <p className="w-100 m-0">
            <span className="secondary bold">
              Add files from Bynder
            </span>
            <br />
            <span className="small">
              .jpg, .png, .mp4
            </span>
          </p>
        </div>
      </div>
      <Modal
        className="modal-hide-footer modal-hide-close story-editor"
        visible={modalStatus !== undefined}
        width={620}
      >
        {settings?.defaultBynderBrand || bynderBrands.length < 2 ? (
          <>
            <div className="d-flex justify-between align-center mb-3">
              <h2 className="mb-0">
                Select images
              </h2>
              <Button
                type="primary"
                onClick={() => setModalStatus()}
              >
                Done
              </Button>
            </div>
            {loadingBynderMedia ? (
              <Spin size="large" />
            ) : (
              <div className="d-flex flex-wrap justify-between flex-gap">
                {bynderMedia.map(image => (
                  <div
                    key={image.id}
                    className={`bynder-image ${story.images.includes(image.thumbnails.webimage) ? 'selected' : ''}`}
                    style={{ backgroundImage: `url(${image.thumbnails.thul})` }}
                    onClick={() => toggleBynderImage(image)}
                  />
                ))}
              </div>
            )}
          </>
        ) : (
          <>
            <h2>
              Pick a brand
            </h2>
            <div className="d-flex align-center">
              {bynderBrands.map(brand => (
                <div
                  key={brand.id}
                  className="d-flex direction-column align-center mr-4 pointer"
                  onClick={() => setBynderBrand(brand)}
                >
                  <img src={brand.image} alt="brand.name" height="120" />
                  <p className={`mt-3 bold ${brand === bynderBrand ? 'highlight' : ''}`}>
                    {brand.name}
                  </p>
                </div>
              ))}
            </div>
            <Checkbox
              checked={saveAsDefaultBrand}
              onChange={() => setSaveAsDefaultBrand(!saveAsDefaultBrand)}
              className="mt-3"
            >
              Save as default brand
            </Checkbox>
            <Button
              className="d-block px-6 mt-3"
              type="primary"
              onClick={() => {
                setSettings({
                  ...settings,
                  defaultBynderBrand: bynderBrand,
                })
                loadBynderMedia(settings.bynderToken, settings.bynderURL, bynderBrand.id)
                if (saveAsDefaultBrand) {
                  setBrandSettings(data.brand.id, 'defaultBynderBrand', bynderBrand)
                }
              }}
            >
              Next
            </Button>
          </>
        )}

      </Modal>
    </>
  )
}
