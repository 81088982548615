import axios from 'axios'

export default function getBynderBrands(accessToken, portalURL) {
  const url = `${process.env.REACT_APP_FUNCTIONS_URL}/getBynderBrands`
  const params = { accessToken, portalURL }

  return axios
    .get(url, { params })
    .then(res => res)
    .catch(error => error)
}
