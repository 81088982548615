import firebase from 'firebase'

export default async function setBrandValue(brandID, id, value) {
  const db = firebase.firestore()
  try {
    const doc = await db.collection('brands').doc(brandID)

    return doc
      .update({ [id]: value })
      .then(() => true)
  } catch (error) {
    return error
  }
}
