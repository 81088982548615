import firebase from 'firebase'

export default async function setBrandSettings(brandID, id, value) {
  const db = firebase.firestore()
  try {
    const docRef = await db.collection('brands').doc(brandID).collection('settings').doc('all')

    const docExists = await docRef
      .get()
      .then(d => d.exists)

    if (docExists) {
      return docRef
        .update({ [id]: value })
        .then(() => true)
    }

    return docRef
      .set({ [id]: value })
      .then(() => true)
  } catch (error) {
    return error
  }
}
