import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as ChevronLeft } from '../icons/chevron-left.svg'
import StoryEditorContent from '../components/dashboard/StoryEditorContent'
import StoryEditorInformation from '../components/dashboard/StoryEditorInformation'

export default function StoryEditor({
  acceptedSuperfans, savedStory, step, data, setData, preselectedSuperfans, setPreselectedSuperfans,
}) {

  const [story, setStory] = useState(savedStory ? {
    ...savedStory,
    imageFiles: [],
  } : {
    imageFiles: [],
    images: [],
    superfans: [],
    categories: [],
    replyTo: data.brand.defaultReplyTo,
  })

  return (
    <div className="story-editor">
      <Link to={`/dashboard/${data.brand.handle}/stories`} className="d-inline-flex align-center regular">
        <ChevronLeft className="mr-1" /> <span className="regular">Stories</span>
      </Link>

      <div className="d-flex justify-between mt-4">
        <h1 className="m-0">
          {story ? 'Edit story' : 'New story'}
        </h1>

        <div className="d-flex justify-end align-baseline">
          <h1 className="secondary m-0">
            Step {step === 'information' ? 2 : 1}
          </h1>
          <h2 className="light m-0">
            /2
          </h2>
        </div>

      </div>
      {step === 'information' && (story.imageFiles.length > 0 || story.images.length > 0) && story.title ? (
        <StoryEditorInformation
          acceptedSuperfans={acceptedSuperfans}
          story={story}
          setStory={setStory}
          data={data}
          setData={setData}
          preselectedSuperfans={preselectedSuperfans}
          setPreselectedSuperfans={setPreselectedSuperfans}
        />
      ) : (
        <StoryEditorContent
          data={data}
          story={story}
          setStory={setStory}
        />
      )}
    </div>
  )
}
