import axios from 'axios'

export default function getBynderMedia(accessToken, portalURL, brandId) {
  const url = `${process.env.REACT_APP_FUNCTIONS_URL}/getBynderMedia`
  const params = { accessToken, portalURL, brandId }

  return axios
    .get(url, { params })
    .then(res => res)
    .catch(error => error)
}
